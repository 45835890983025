import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet-async';
import Img from 'gatsby-image';
import { Typography } from '@material-ui/core';
import Layout from '../components/Layout';
import Container from '../components/Container';
import ContentfulRichText from '../components/contentfulRichText';
import NextItemLink from '../components/NextItemLink';
import { ContentfulBlogPost } from '../../types/graphql-types';

interface Props {
  data: {
    site: {
      siteMetadata: {
        id?: string;
        title?: string;
      };
    };
    contentfulBlogPost: ContentfulBlogPost;
  };
  pageContext?: {
    slug: string;
    next?: ContentfulBlogPost;
  };
}

const BlogPostTemplate: React.FC<Props> = ({ data, pageContext }: Props) => {
  return (
    <Layout>
      <Container variant="wide">
        <Helmet
          title={`${data.contentfulBlogPost.title} | ${data?.site?.siteMetadata?.title}`}
        />
        <div style={{ marginTop: '6rem' }} />
        <Container variant="small" noPad>
          <Typography variant="h2" component="h1" color="primary">
            {data.contentfulBlogPost.title}
          </Typography>
          <Typography variant="h3" component="h2" color="textSecondary">
            {data.contentfulBlogPost.subtitle}
          </Typography>
        </Container>
        <div style={{ marginTop: '2rem' }} />

        {data.contentfulBlogPost.featuredImage ? (
          <>
            <Img
              alt={data.contentfulBlogPost.title || ''}
              fluid={data.contentfulBlogPost.featuredImage.fluid}
            />
            <div style={{ marginTop: '2rem' }} />
          </>
        ) : null}
        <Container variant="small" noPad>
          <ContentfulRichText document={data?.contentfulBlogPost?.body?.json} />
          {pageContext?.next ? (
            <NextItemLink
              path={`/blog/${pageContext?.next.slug}`}
              title={pageContext?.next.title || ''}
              subtitle={pageContext?.next.subtitle || undefined}
            />
          ) : null}
        </Container>
      </Container>
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      subtitle
      updatedAt(formatString: "YYYY, MMMM Do")
      featuredImage {
        fluid(maxWidth: 1180) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      body {
        json
      }
    }
  }
`;
